import {makeStyles} from "@material-ui/styles";
import {useEffect, useState} from "react";
import 'moment-timezone';
import {useTranslation} from "tools/i18n";

const useStyles = makeStyles({
    countdown: {
        width: '100%',
        padding: '25px',
        background: '#000',
        color: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 1,
        flexDirection: 'column',
        fontFamily: 'Chronicle',
    },
    countdownPayoff: {
        margin: 'auto',
        marginBottom: '15px',
        fontSize: '30px'
    },
    countdownTimer: {
        margin: 'auto',
        fontSize: '52px',
    },
}, {name: 'CountdownBanner'});

// TODO BEFORE USE.
// * ADD START AND END DATE PROPS
// * ADD A VISIBILITY TIMEFRAME
// * CREATE AS A CMS COMPONENT IN SHOPWARE
export default function CountdownBanner() {
    const classes = useStyles();
    const {t} = useTranslation();
    const [countdown, setCountdown] = useState({
        showCountdown: false,
        countdownTimer: '23h 59m 59s',
        target: new Date("2024-12-02T23:59:59").getTime()
    })

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const timeLeft = countdown.target - now;

            if (timeLeft <= 0) {
                clearInterval(interval); // Stop the timer
                setCountdown({
                    ...countdown,
                    showCountdown: false,
                    countdownTimer: '23h 59m 59s',
                });
            } else {
                const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

                const formattedTime = `${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;

                setCountdown((prevState) => ({
                    ...prevState,
                    showCountdown: true,
                    countdownTimer: formattedTime,
                }));
            }
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [countdown.target]);

	return (
            countdown.showCountdown &&
                <div className={classes.countdown}>
                    <p className={classes.countdownPayoff}>
                        Sale ends at midnight!
                    </p>
                    <p className={classes.countdownTimer}>
                        { countdown.countdownTimer }
                    </p>
                </div>
	);
}
